import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { State } from 'src/app/views/user-management/redux/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IOrganisation } from '../model/user-management.model';
import { selectAuthUserOrganisations, selectAuthUserOrgById} from '../redux/selectors';

@Injectable({
  providedIn: 'root'
})
export class UserOrganisationResolver  {

  constructor(private store: Store<State>, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IOrganisation> {
    const urlOrgIds = state.url.split('/').filter((path: string) => path.includes('org'));
    // If there are more than one orgId in the url, user is super admin
    if (urlOrgIds.length > 1) {
      return this.store.select(selectAuthUserOrganisations).pipe(
        filter(org => !!org?.length),
        take(1)
      )
    } 

    const orgId: string = urlOrgIds[0] ? urlOrgIds[0] : route.parent.data.user?.currentOrgId;
    return this.store.select(selectAuthUserOrgById(orgId)).pipe(
      filter(org => !!org),
      take(1)
    );
  }
}
